.red {
	color:red
}
.black {
	color: black
}
.green {
	color: green
}
.buttonMid {
	position: relative; 
	top: 8px;
	height: 55px ;
}
.imageFrame {
	background-color:antiquewhite;
	margin: 2px;
	padding: 8px;
}