.Welcome {
    text-align: center;
}

.Welcome-header {
    background-color: midnightblue ;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1vmin);
    color: white;
}